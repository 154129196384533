import axios from 'axios';
import { ButtonGroup, ComboBox, DataGrid, Dialog, Form, FormField, GridColumn, Label, Layout, LayoutPanel, LinkButton, Panel, TextBox, NumberBox } from 'rc-easyui';
// import "antd/dist/antd.css";
import { Button, DatePicker, TimePicker, Checkbox, Select, Space, Flex, Radio } from 'antd';
import React, { Component, useState, useEffect } from "react";
import { uAPI } from "./config/Config";
import Header from "./Header";
import Loader from 'react-loader-spinner';
import {
    Row,
    Col,
} from "react-bootstrap";
import { useHistory, Redirect } from "react-router-dom";
import Cookies from 'js-cookie';

const instance = axios.create({
    withCredentials: true,
});

class MasterTest extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirctTo: false,
            master_test_category: null,
            syaratKetentuan: null,
            _selectedCategory: null,
            _selectedSK: null,
            _selectedSubCategory: null,
            master_test: {
                total: 0,
                pageSize: 50,
                data: [],
                pagePosition: "bottom",
                pageOptions: {
                    layout: ['list', 'sep', 'first', 'prev', 'next', 'last', 'sep', 'refresh', 'sep', 'manual', 'info']
                },
                selection: null,
                loading: true,
            },
            formTambah: {
                field: {
                    id_masterTest: "",
                    id: "",
                    desc: "",
                    subcategory: "",
                    price: "",
                    sk: "",
                    category: "",
                    order: "",
                },
                rules: {
                    id_masterTest: ["required"],
                    id: ["required"],
                    desc: ["required"],
                    subcategory: ["required"],
                    price: ["required"],
                    sk: ["required"],
                    category: ["required"],
                    order: ["required"],
                }
            },
            formEdit: {
                field: {
                    id_masterTest: "",
                    id: "",
                    desc: "",
                    subcategory: "",
                    price: "",
                    sk: "",
                    category: "",
                    order: "",
                },
                // rules: {
                //     id_masterTest: ["required"],
                //     id: ["required"],
                //     desc: ["required"],
                //     subcategory: ["required"],
                //     price: ["required"],
                //     sk: ["required"],
                //     category: ["required"],
                //     order: ["required"],
                // }
            },
            loader: false,
        }
        this.errorDialog = React.createRef();
        this.tambahConc = React.createRef();
        this.editConc = React.createRef();

        this.pageChange = this.pageChange.bind(this);
        this.selectionChange = this.selectionChange.bind(this);

        this.formTambah = React.createRef();
        this.handleChangeFormTambah = this.handleChangeFormTambah.bind(this);
        this.handleSubmitFormTambah = this.handleSubmitFormTambah.bind(this);

        this.formEdit = React.createRef();
        this.handleEditClick = this.handleEditClick.bind(this)
        this.handleChangeformEdit = this.handleChangeformEdit.bind(this);
        this.handleSubmitformEdit = this.handleSubmitformEdit.bind(this);

        this.removeDialog = React.createRef();
        this.removedData = this.removedData.bind(this);
        this.handleRemoveClick = this.handleRemoveClick.bind(this);
        this.getBranch = this.getBranch.bind(this);
    }

    async getData() {
        instance.defaults.headers.post["X-CSRF-Token"] = Cookies.get('rujukan-csrf');
        instance
            .post(uAPI + "/api/v1/getMasterTest")
            .then(function (response) {
                return response.data.results;
            })
            .then(response => {
                console.log(response)

                var uniqueArray = response.reduce(function (a, d) {
                    if (a.indexOf(d.category) === -1) {
                        a.push(d.category);
                    }
                    return a;
                }, []);

                var _U = [];
                uniqueArray.map((item, i) => {
                    _U.push({ value: item, label: item });
                })

                console.log("uniqueArray", _U);

                this.setState({
                    master_test_category: _U,
                    master_test: {
                        ...this.state.master_test,
                        data: response,
                        loading: false
                    }
                })
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    async getBranch() {
        await axios.post(uAPI + `/api/v1/branch`)
            .then(response =>
                // console.log("ini Branch",response),
                response.data.results.map(item => ({
                    value: `${item.branch_code}`,
                    text: `${item.branch_name}`,
                }))
            )
            .then(Branch => {
                this.setState({
                    Branch: Branch,
                    chooseBranch: Branch[0],
                })
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    async getSK() {
        instance.defaults.headers.post["X-CSRF-Token"] = Cookies.get('rujukan-csrf');
        await instance.post(uAPI + `/api/v1/syaratKetentuan`)
            .then(response =>
                // console.log("ini Branch",response),
                response.data.results.map(item => ({
                    value: `${item.desc}`,
                    text: `${item.id_sk}`,
                }))
            )
            .then(sk => {
                console.log("ini sk", sk)
                this.setState({
                    syaratKetentuan: sk,
                })
                // this.setState({
                //     Branch: Branch,
                //     chooseBranch: Branch[0],
                // })
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    changeSelectBranch = (e) => {
        this.setState({ chooseBranch: e })
    }

    pageChange(event) {
        // console.log(event)
        if (event.refresh) {
            // console.log(event)
            this.setState({
                master_test: {
                    ...this.state.master_test,
                    loading: true,
                    selection: null
                },
            })
            this.getData();
        }

    }
    async selectionChange(event) {
        // console.log(event)
        // let indexBranch = this.state.Branch.findIndex(function (item, i) {
        //     return item.value == event.branch_code
        // });

        await this.setState({
            master_test: {
                ...this.state.master_test,
                selection: event
            },
            // chooseBranch: this.state.Branch[indexBranch],
        })
    }

    async handleChangeFormTambah(name, value) {
        let field = Object.assign({}, this.state.formTambah.field);
        field[name] = value;
        await this.setState({
            formTambah: {
                ...this.state.formTambah,
                field: field
            }
        }, () => console.log(this.state.formTambah.field))
    }

    async handleSubmitFormTambah() {
        const formData = new FormData();
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        };
        const NAMA_KONSULTASI = this.state.formTambah.field.NAMA_KONSULTASI;
        const KATEGORI_KONSULTASI = this.state.formTambah.field.KATEGORI_KONSULTASI;
        const HARGA_KONSULTASI = this.state.formTambah.field.HARGA_KONSULTASI;
        const KODE_KONSULTASI = this.state.formTambah.field.KODE_KONSULTASI;
        formData.append('NAMA_KONSULTASI', NAMA_KONSULTASI);
        formData.append('KATEGORI_KONSULTASI', KATEGORI_KONSULTASI);
        formData.append('HARGA_KONSULTASI', HARGA_KONSULTASI);
        formData.append('KODE_KONSULTASI', KODE_KONSULTASI);
        formData.append('branch_code', this.state.chooseBranch);

        for (var pair of formData.entries()) {
            console.log(pair[0] + ': ' + pair[1]);
        }

        if (NAMA_KONSULTASI == '' || KATEGORI_KONSULTASI == '' || HARGA_KONSULTASI == '' || KODE_KONSULTASI == '') {

            this.setState({
                errorMessage: "Form tidak boleh kosong!",
            })
            this.errorDialog.current.open();

        } else if (KODE_KONSULTASI.length > 2) {

            this.setState({
                errorMessage: "Kode poli maksimal 2 Karakter!",
            })
            this.errorDialog.current.open();
        } else {
            await axios.post(uAPI + '/api/v1/master_test', formData, config)
                .then(response => {
                    // console.log(response)
                    if (response.data.status === 406) {
                        this.setState({
                            errorMessage: response.data.message,
                        })
                        this.errorDialog.current.open();
                    } else {
                        this.getData();
                        this.tambahConc.current.close();
                        this.setState({
                            formTambah: {
                                ...this.state.formTambah,
                                field: {
                                    NAMA_KONSULTASI: "",
                                    KATEGORI_KONSULTASI: "",
                                    HARGA_KONSULTASI: "",
                                    KODE_KONSULTASI: "",
                                },
                            },
                            chooseBranch: this.state.Branch[0],
                        })
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        }

    }

    async handleRemoveClick() {
        if (this.state.master_test.selection) {
            this.removeDialog.current.open();
        } else {
            // console.log('tidak ada yang di select');
            this.setState({
                errorMessage: "Tidak Ada Data yang Dipilih",
            })
            this.errorDialog.current.open();
        }
    }

    async removedData() {
        await this.setState({
            loader: true
        })
        const id = this.state.master_test.selection.ID_KONSULTASI
        await axios.delete(uAPI + `/api/v1/master_test/${id}`)
            .then(function (response) {
                // console.log(response)this.state.pasien.selection.id_regis
                return response.data;
            })
            .then(response => {
                // console.log(response)
                this.getData();
                this.removeDialog.current.close();
                this.setState({
                    loader: false,
                    master_test: {
                        ...this.state.master_test,
                        selection: null
                    },
                })




            })
            .catch(error => {
                console.log(error);
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    // console.log(error.response.data);
                    // console.log(error.response.status);
                    // console.log(error.response.headers);
                    if (error.response.status === 500) {
                        this.setState({
                            loader: false,
                            errorMessage: error.response.data,
                        })
                        this.errorDialog.current.open();
                    }
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    // console.log(error.request);
                    this.setState({
                        loader: false,
                        errorMessage: "Terjadi Kesalahan",
                    })
                    this.errorDialog.current.open();
                } else {
                    // Something happened in setting up the request that triggered an Error
                    // console.log('Error', error.message);
                    this.setState({
                        loader: false,
                        errorMessage: "Terjadi Kesalahan",
                    })
                    this.errorDialog.current.open();
                }

            });
    }

    async handleEditClick() {
        if (this.state.master_test.selection) {
            console.log(this.state.master_test.selection)

            const id_masterTest = `${this.state.master_test.selection.id_masterTest}`
            const id = `${this.state.master_test.selection.id}`
            const desc = `${this.state.master_test.selection.desc}`
            let subcategory = null;
            const price = `${this.state.master_test.selection.price}`
            let sk = [];
            const category = `${this.state.master_test.selection.category}`
            const order = `${this.state.master_test.selection.order}`

            if (this.state.master_test.selection.subcategory) {
                subcategory = "true";
            } else {
                subcategory = "false"
            }

            if (this.state.master_test.selection.sk) {
                sk = this.state.master_test.selection.sk.split(',');
            } else {
                sk = []
            }

            this.setState({
                _selectedSubCategory: [{ value: subcategory, label: subcategory }],
                _selectedCategory: [{ value: category, label: category }],
                _selectedSK: sk,
                formEdit: {
                    ...this.state.formEdit,
                    field: {
                        id_masterTest: id_masterTest,
                        id: id,
                        desc: desc,
                        subcategory: subcategory,
                        price: price,
                        sk: sk,
                        category: category,
                        order: order,
                    },
                },
            })

            this.editConc.current.open();
        } else {
            // console.log('tidak ada yang di select');
            this.setState({
                errorMessage: "Tidak Ada Data yang Dipilih",
            })
            this.errorDialog.current.open();
        }
    }

    async handleChangeformEdit(name, value) {
        let field = Object.assign({}, this.state.formEdit.field);
        field[name] = value;
        await this.setState({
            formEdit: {
                ...this.state.formEdit,
                field: field
            }
        })
    }
    async handleSubmitformEdit() {

        const id_masterTest = this.state.formEdit.field.id_masterTest;
        const id = this.state.formEdit.field.id;
        const desc = this.state.formEdit.field.desc;
        let subcategory = null;
        if (this.state._selectedSubCategory[0].value == "true") {
            subcategory = "true"
        } else {
            subcategory = null
        }

        const price = this.state.formEdit.field.price;
        let sk = "";
        if (this.state._selectedSK.length > 0) {
            sk = this.state._selectedSK.toString();
        } else {
            sk = null;
        }

        // alert(this.state._selectedSK.toString());

        const category = this.state._selectedCategory[0].value;
        const order = this.state.formEdit.field.order;

        let formData = {
            id_masterTest: id_masterTest,
            id: id,
            desc: desc,
            subcategory: subcategory,
            price: price,
            sk: sk,
            category: category,
            order: order,
        }


        if (id == '') {

            this.setState({
                errorMessage: "Form tidak boleh kosong!",
            })
            this.errorDialog.current.open();

        } else {

            instance.defaults.headers.post["X-CSRF-Token"] = Cookies.get('rujukan-csrf');
            await instance.post(uAPI + '/api/v1/updateTest', formData)
                .then(response => {
                    if (response.data.status === 406) {
                        this.setState({
                            errorMessage: response.data.message,
                        })
                        this.errorDialog.current.open();
                    } else {
                        this.setState({
                            master_test: {
                                ...this.state.master_test,
                                loading: true
                            }
                        })
                        this.getData();
                        this.editConc.current.close();
                        // this.setState({
                        //     formEdit: {
                        //         ...this.state.formEdit,
                        //         field: {
                        //             NAMA_KONSULTASI: "",
                        //             KATEGORI_KONSULTASI: "",
                        //             HARGA_KONSULTASI: "",
                        //             KODE_KONSULTASI: "",
                        //         },
                        //     },
                        //     chooseBranch: this.state.Branch[0],
                        // })
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });

        }

    }

    componentDidMount() {

        if (!Cookies.get('rujukan-login')) {
            this.setState({ redirctTo: true })
        } else { }

        this.getData();
        this.getSK();
        // this.getBranch()
    }
    componentWillUnmount() {
        window.location.reload();
    }
    render() {

        if (this.state.redirctTo) {
            return (<Redirect
              to={{
                pathname: "/",
                login_alert: {
                    msg: "Mohon login terlebih dahulu!",
                },
              }} />)
          } else { }

        const handleChangeForSubCategory = (value) => {
            this.setState({ _selectedSubCategory: [{ value: value, label: value }] }, () => console.log("ok"))
        };

        const handleChangeForCategory = (value) => {
            this.setState({ _selectedCategory: [{ value: value, label: value }] }, () => console.log("ok"))
        };

        const handleChangeForSK = (value) => {
            this.setState({ _selectedSK: value }, () => console.log("ok"))
        };

        const { Option } = Select;
        const children = [];

        if (this.state.syaratKetentuan) {
            this.state.syaratKetentuan.map((item, i) => {
                console.log("daftar item", item)
                children.push(<Option key={item.text}>{item.value}</Option>);
            })
        }

        console.log(children)

        return (

            <>

                <Header />

                <div
                    className="container-fluid"
                    style={{ fontFamily: "var(--font-family-gotha-boo)" }}
                >
                    <div className="row no-gutter">
                        <div className="col-md-12">


                            <div style={{ height: 400, width: "100%", padding: "20px" }} >

                                <Row>


                                    <Dialog
                                        title="Alert"
                                        style={{ width: '300px', height: '84px' }}
                                        modal
                                        closed={true}
                                        ref={this.errorDialog}
                                    >
                                        <p style={{ textAlign: 'center', margin: '10px', fontSize: '16px' }}>{this.state.errorMessage}</p>
                                    </Dialog>

                                    <Dialog title="Tambah Konsultasi"
                                        style={{ width: '500px', }}
                                        modal
                                        closed={true}
                                        ref={this.tambahConc}
                                    >
                                        <div className='pd-20'>
                                            <Form
                                                ref={this.formTambah}
                                                style={{ maxWidth: 500 }}
                                                model={this.state.formTambah.field}
                                                rules={this.state.formTambah.rules}
                                                labelWidth={200}
                                                // labelAlign="right"
                                                labelPosition="top"
                                                // floatingLabel
                                                // validateRules={this.getCustomRules}
                                                onChange={this.handleChangeFormTambah}
                                            >
                                                <FormField name="NAMA_KONSULTASI" label="NAMA KONSULTASI">
                                                    {/* <ComboBox data={this.state.Test_Code} value={this.state.formTambah.field.Test_ID} ></ComboBox> */}
                                                    <TextBox inputId="t1" name="ftestid" value={this.state.formTambah.field.NAMA_KONSULTASI} style={{ width: '100%' }}></TextBox>
                                                </FormField>
                                                <FormField name="KATEGORI_KONSULTASI" label="KATEGORI POLI">
                                                    <TextBox inputId="t2" name="ftestname" value={this.state.formTambah.field.KATEGORI_KONSULTASI} style={{ width: '100%' }}></TextBox>
                                                </FormField>
                                                <FormField name="KODE_KONSULTASI" label="KODE KONSULTASI / POLI">
                                                    <TextBox inputId="t4" name="fconcid" value={this.state.formTambah.field.KODE_KONSULTASI} style={{ width: '100%' }}></TextBox>
                                                </FormField>
                                                <FormField name="HARGA_KONSULTASI" label="HARGA KONSULTASI / POLI">
                                                    <TextBox inputId="t3" name="fconcid" value={this.state.formTambah.field.HARGA_KONSULTASI} style={{ width: '100%' }}></TextBox>
                                                </FormField>


                                                <FormField name="branch_code" label="BRANCH">
                                                    <ComboBox onChange={this.changeSelectBranch}
                                                        style={{ width: '100%', height: '40px' }} data={this.state.Branch} value={this.state.chooseBranch} ></ComboBox>
                                                </FormField>

                                                <FormField>
                                                    <LinkButton onClick={this.handleSubmitFormTambah} >Submit</LinkButton>
                                                </FormField>
                                            </Form>
                                        </div>
                                    </Dialog>

                                    <Dialog title="Edit Konsultasi"
                                        style={{ width: '500px', fontFamily: "var(--font-family-gotha-boo)" }}
                                        modal
                                        closed={true}
                                        ref={this.editConc}
                                    >
                                        <div className='pd-20'>
                                            <Form
                                                ref={this.formEdit}
                                                style={{ maxWidth: 500, height: 400 }}
                                                model={this.state.formEdit.field}
                                                rules={this.state.formEdit.rules}
                                                labelWidth={200}
                                                // labelAlign="right"
                                                labelPosition="top"
                                                // floatingLabel
                                                // validateRules={this.getCustomRules}
                                                onChange={this.handleChangeformEdit}
                                                virtualScroll
                                            >

                                                <FormField name="id" label="ID">
                                                    <TextBox inputId="t1" name="fid" value={this.state.formEdit.field.id} style={{ width: '100%' }}></TextBox>
                                                </FormField>
                                                <FormField name="desc" label="PEMERIKSAAN">
                                                    <TextBox inputId="t2" name="fdesc" value={this.state.formEdit.field.desc} style={{ width: '100%' }}></TextBox>
                                                </FormField>

                                                {/* <FormField name="subcategory" label="SUBCATEGORI">
                                                    <TextBox inputId="t3" name="fsubcategory" value={this.state.formEdit.field.subcategory} style={{ width: '100%' }}></TextBox>
                                                </FormField> */}

                                                <FormField name="subcategory" label="SUB KATEGORI">
                                                    <Select
                                                        value={this.state._selectedSubCategory}
                                                        style={{ width: '100%' }}
                                                        onChange={handleChangeForSubCategory}
                                                        options={[
                                                            {
                                                                "value": "true",
                                                                "label": "true"
                                                            },
                                                            {
                                                                "value": "false",
                                                                "label": "false"
                                                            },
                                                        ]}
                                                    />
                                                </FormField>

                                                <FormField name="price" label="HARGA">
                                                    <NumberBox inputId="t4" name="fprice" value={this.state.formEdit.field.price} style={{ width: '100%' }}></NumberBox>
                                                </FormField>



                                                <FormField name="category" label="KATEGORI">
                                                    <Select
                                                        value={this.state._selectedCategory}
                                                        style={{ width: '100%' }}
                                                        onChange={handleChangeForCategory}
                                                        options={this.state.master_test_category}
                                                    />
                                                </FormField>


                                                <FormField name="category" label="SYARAT & KETENTUAN">

                                                    {children ? (

                                                        <Select
                                                            mode="multiple"
                                                            value={this.state._selectedSK}
                                                            placeholder="Please select"
                                                            onChange={handleChangeForSK}
                                                            style={{ width: "100%" }}
                                                        >
                                                            {children}
                                                        </Select>
                                                    ) : null}

                                                </FormField>

                                                <FormField name="order" label="ORDER">
                                                    <NumberBox inputId="t7" name="forder" value={this.state.formEdit.field.order} style={{ width: '100%' }}></NumberBox>
                                                </FormField>

                                                {/* <FormField name="branch_code" label="BRANCH">
                                                    <ComboBox onChange={this.changeSelectBranch}
                                                        style={{ width: '100%', height: '40px' }} data={this.state.Branch} value={this.state.chooseBranch} ></ComboBox>
                                                </FormField> */}
                                                <FormField >
                                                    <LinkButton style={{marginBottom:'40px'}} onClick={this.handleSubmitformEdit} >Submit</LinkButton>
                                                </FormField>
                                            </Form>
                                        </div>
                                    </Dialog>
                                    <Dialog
                                        title="Please Confirm"
                                        style={{ width: '300px', height: '190px' }}
                                        modal
                                        closed={true}
                                        ref={this.removeDialog}
                                        footer={() => {
                                            return (
                                                this.state.loader ? (
                                                    <div className="text-center">
                                                        <Loader type="TailSpin" color="#00BFFF" height={40} width={100} />
                                                    </div>
                                                ) : (

                                                    <>
                                                        <ButtonGroup style={{ width: '100%', height: '50px' }}>
                                                            <LinkButton className="f-full mybtn" plain onClick={this.removedData}>Yakin</LinkButton>
                                                            <LinkButton className="f-full mybtn" plain onClick={() => this.removeDialog.current.close()}>Cancel</LinkButton>
                                                        </ButtonGroup>
                                                    </>
                                                )
                                            )
                                        }}

                                    >
                                        <>
                                            <p style={{ textAlign: 'center', margin: '10px', fontSize: '16px' }}>Apakah Anda Yakin ?</p>

                                        </>
                                    </Dialog>


                                    <Col md="12" style={{ backgroundColor: "white", padding: "2px" }}>
                                        <Panel
                                            title="Master Test"
                                            collapsible
                                        // bodyStyle={{ padding: 20 }}
                                        // style={{ height: 500 }}
                                        >
                                            <Row style={{ padding: 0, marginBottom: 4, marginLeft: 2 }}>
                                                <Col className="col-8">
                                                    <div style={{ marginTop: 10, marginLeft: 4 }}>
                                                        {/* <LinkButton
                                                            iconCls="icon-add"
                                                            style={{ margin: 4 }}
                                                            // onClick={() => this.addRow()}
                                                            onClick={() => this.tambahConc.current.open()}
                                                        >
                                                            Tambah
                                                        </LinkButton> */}
                                                        <LinkButton
                                                            iconCls="icon-edit"
                                                            style={{ margin: 4 }}
                                                            // onClick={() => this.editRow()}
                                                            onClick={this.handleEditClick}
                                                            disabled={this.state.master_test.selection ? false : true}
                                                        >
                                                            Update
                                                        </LinkButton>
                                                        {/* <LinkButton
                                                            iconCls="icon-cancel"
                                                            style={{ margin: 4 }}
                                                            // onClick={() => this.deleteRow()}
                                                            onClick={this.handleRemoveClick}
                                                            disabled={this.state.master_test.selection ? false : true}
                                                        >
                                                            Hapus
                                                        </LinkButton> */}
                                                    </div>
                                                </Col>
                                            </Row>

                                            <div id="DataGrid">
                                                <DataGrid
                                                    {...this.state.master_test}
                                                    style={{ height: '70vh', }}
                                                    pagination
                                                    columnResizing
                                                    selectionMode="single"
                                                    onSelectionChange={this.selectionChange}
                                                    // onPageChange={this.pageChange}
                                                    filterable
                                                    virtualScroll
                                                    columnMoving
                                                >
                                                    {/* <GridColumn
                field="rn"
                align="center"
                width="30px"
                cellCss="datagrid-td-rownumber"
                render={({ rowIndex }) => <span>{rowIndex + 1}</span>}
                filterable={false}
            /> */}
                                                    {/* <GridColumn
                field="ID_KONSULTASI"
                title="ID"
                align="center"
                width="40px"
            ></GridColumn> */}
                                                    <GridColumn
                                                        sortable
                                                        field="id"
                                                        title="ID"
                                                        align="center"
                                                        width="40px"
                                                    ></GridColumn>
                                                    <GridColumn
                                                        field="desc"
                                                        title="PEMERIKSAAN"
                                                        align="center"
                                                        width="160px"
                                                        render={({ row }) => {
                                                            return <span style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>{row.desc}</span>
                                                        }}
                                                    ></GridColumn>
                                                    <GridColumn
                                                        field="subcategory"
                                                        title="SUBCATEGORY"
                                                        align="center"
                                                        width="60px"
                                                    ></GridColumn>
                                                    <GridColumn
                                                        field="price"
                                                        title="HARGA"
                                                        align="center"
                                                        width="100px"
                                                    ></GridColumn>
                                                    <GridColumn
                                                        field="sk"
                                                        title="SK"
                                                        // hidden="true"
                                                        align="center"
                                                        width="20px"
                                                    ></GridColumn>
                                                    <GridColumn
                                                        field="category"
                                                        title="KATEGORI"
                                                        align="center"
                                                        width="100px"
                                                    ></GridColumn>
                                                    <GridColumn
                                                        field="order"
                                                        title="ORDER"
                                                        // hidden="true"
                                                        align="center"
                                                        width="40px"
                                                    ></GridColumn>
                                                </DataGrid>
                                            </div>
                                        </Panel>
                                    </Col>
                                </Row>

                            </div>

                        </div>
                    </div>
                </div>

            </>

        )
    }
}
export default MasterTest