import React, { useState, useEffect } from "react";
import { useHistory, Redirect } from "react-router-dom";
// import "./assets/login.scss";
import Loader from "react-loader-spinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { uAPI, IP } from "./config/Config";
import logo from "./images/bio_logo.png";
import bg316 from "./images/bg316.png";
import queryString from "query-string";
import Modal from "react-bootstrap/Modal";
import Swal from "sweetalert2";
import CryptoJS from "crypto-js";
import { useCookies } from "react-cookie";
import Cookies from 'js-cookie';

import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  LoadCanvasTemplateNoReload,
  validateCaptcha,
} from "react-simple-captcha";
import { v4 as uuidv4 } from "uuid";

export default function Login() {
  const instance = axios.create({
    withCredentials: true,
  });

  const location = useHistory().location;
  const query = queryString.parse(location.search);

  const history = useHistory();
  const [rememberMe, setRememberMe] = React.useState();
  const [petugas, setPetugas] = React.useState(false);
  const [login, setLogin] = React.useState(
    localStorage.rememberMe ? true : false
  );
  const [detail_login, setDetail_login] = React.useState(
    localStorage.rememberMe
      ? JSON.parse(localStorage.getItem("detail_login"))
      : null
  );

  const [hidden, setHidden] = React.useState(true);
  const [loader, setLoader] = React.useState(false);
  const [login_alert, setLogin_alert] = React.useState(
    history.location.login_alert ? history.location.login_alert.msg : null
  );

  const [captcha, setCaptcha] = React.useState(false);
  const [loginNotif, setLoginNotif] = React.useState(false);
  const [recentUser, setRecentUser] = React.useState();
  const [statusAlert, setStatusAlert] = React.useState(
    history.location.status ? history.location.status : null
  );
  const [loginSession, setLoginSession] = React.useState();
  const [admin, setAdmin] = React.useState(false);

  const INITIAL_STATE = {
    email_user: "",
    pid_user: "",
    password: "",
  };

  const [state, setState] = React.useState(INITIAL_STATE);
  const { email_user, pid_user, password } = state;
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [displayReset, setDisplayReset] = React.useState("block");
  const [overlay, setOverlay] = React.useState("none");
  const [CSRFToken, setCSRFToken] = React.useState();
  const [cookies, setCookie, removeCookie] = useCookies();
  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-success",
      cancelButton: "btn btn-danger",
    },
    buttonsStyling: false,
  });

  const loginError = async (msg) => {
    await swalWithBootstrapButtons
      .fire({
        icon: "error",
        title: msg,
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      })
      .then(function () {
        setLoader(false);
      });
  };

  const loginSuccess = async (msg) => {
    await swalWithBootstrapButtons
      .fire({
        icon: "success",
        title: msg,
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      })
      .then(function () {
        setLoader(false);
        setLogin(true);
      });
  };

  const swalSuccess = async (msg) => {
    await swalWithBootstrapButtons.fire({
      icon: "success",
      title: msg,
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });
  };

  const [tomail, settomail] = useState("");
  const re =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

  /**
   * sendmail digunakan untuk validasi email yang akan direset
   * dimulai dengan pengecekan format email, membandingkan antara
   * state tomail dan variabel re jika format benar selanjutnya
   * email dikirim untuk validasi
   * apakah email yang diinput valid atau terdaftar?
   */

  async function sendmail() {
    if (re.test(String(tomail)) === false) {
      loginError("Format email salah!");
    } else {
      //  setDisplayReset("none");
      setOverlay("flex");
      instance.defaults.headers.post["X-CSRF-Token"] = CSRFToken;
      await instance
        .post(uAPI + "/api/v1/auth_email", {
          email_user: tomail.toLowerCase(),
        })
        .then((response) => {
          return response.data;
        })
        .then((JsonData) => {
          const status = JsonData.status;
          if (status !== 200) {
            loginError(JsonData.results);
            setDisplayReset("block");
            setOverlay("none");
          } else {
            setRecentUser(JsonData.results[0].nama_user);
            requestResetPass(JsonData.results[0].nama_user);
          }
        })
        .catch((error) => {
          loginError("Terjadi Kesalahan!");
        });
    }
  }

  async function requestResetPass(name) {
    let uuid = await uuidv4();
    // console.log(uuid);
    instance.defaults.headers.post["X-CSRF-Token"] = CSRFToken;
    await instance
      .post(uAPI + "/api/v1/requestResetPass", {
        email: tomail.toLowerCase(),
        kode_unik: uuid,
        status: 0,
      })
      .then((response) => {
        // console.log("response", response.data);
        nodemailer(uuid, name);
      })
      .catch((error) => {
        loginError("Terjadi Kesalahan!");
      });
  }

  async function nodemailer(key, name) {
    instance.defaults.headers.post["X-CSRF-Token"] = CSRFToken;
    await instance
      .post(uAPI + "/api/v1/mail", {
        username: name,
        tomail: tomail,
        url: IP + "/reset-password?token=" + key,
      })
      .then((response) => {
        if (response.data.msg === "success") {
          setDisplayReset("block");
          setOverlay("none");
          handleClose();
          swalSuccess("Berhasil Dikirim!");
        } else if (response.data.msg === "fail") {
          alert("Oops, something went wrong. Try again");
          setDisplayReset("block");
        }
      });
  }

  function onClose_() {
    setLoader(false);
    setLogin(true);
  }

  function toggleShow() {
    setHidden(!hidden);
  }

  function onChange(e) {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  }

  function onChangeRemember(e) {
    setRememberMe(e.target.checked);
  }

  function onChangePetugas(e) {
    if (e.target.checked) {
      document.getElementById(`inputEmail`).value = "";
    } else {
      document.getElementById(`inputPID`).value = "";
    }
    // document.getElementById(`inputPID`).value = "";
    // document.getElementById(`inputEmail`).value = "";
    setPetugas(e.target.checked);
  }


  useEffect(() => {


    if (captcha === false && cookies["rujukan-login"] == null) {
      setCaptcha(true);
    }

    if (email_user != "" || password != "" || pid_user != "") {
      setCaptcha(true);
    }

    if (captcha === false && cookies["rujukan-login"] == null) {
      loadCaptchaEnginge(4, "#CCD9DD", "#000", "numbers");
    }

    if (loginNotif === false && login_alert != null) {
      swalWithBootstrapButtons.fire({
        icon: statusAlert == "logout" ? "success" : "error",
        title: login_alert,
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      setCaptcha(true);
    }

    if (login_alert != null && cookies["rujukan-login"] == null) {
      setLoginNotif(true);
      setCaptcha(true);
    }
  }, []);



  async function login_(e) {
    e.preventDefault();
    await setLoader(true);
    let user_captcha = document.getElementById("captha").value;
    if (validateCaptcha(user_captcha) == true) {
      loadCaptchaEnginge(4, "#CCD9DD", "#000", "numbers");
      document.getElementById("captha").value = "";
      if (email_user === "" && password === "") {
        await loginError("Email dan password harus diisi!");
      } else {
        await instance
          .get(uAPI + "/app/login?email_user=" + email_user + "&password=" + password)
          .then((response) => {
            console.log("ini response", response)
            let status = response.data.status;
            if (status !== 200) {
              //   loginError(JsonData.results);
              loginError("Login Gagal! Email atau Password Salah!");
            } else {
              if (rememberMe) {
                localStorage.rememberMe = rememberMe;
                localStorage.setItem("detail_login", JSON.stringify(detail_login));
              }

              let randomKey = Array(6).fill(0).map(x => Math.random().toString(36).charAt(2)).join('');
              setDetail_login({
                d_u: CryptoJS.AES.encrypt(
                  response.data.id_user.toString(),
                  randomKey
                ).toString(),
                kode_perusahaan: response.data.kode_perusahaan,
                nama_perusahaan: response.data.nama_perusahaan,
                idp: response.data.id_perusahaan,
                nama_user: response.data.nama_user,
                p_: randomKey,
                level: response.data.level.toString(),
              });

              if (response.data.level == 1) {
                setAdmin(true);
              }

              // localStorage.status_login = true;
              loginSuccess("Login berhasil!");

              setTimeout(() => {
                if (cookies["rujukan-csrf"]) {
                  const formData = new FormData();
                  const config = { headers: { 'content-type': 'multipart/form-data' } };
                  formData.append('id_user', response.data.id_user);
                  formData.append('name', response.data.nama_user);
                  formData.append('message', "Login");
                  instance.defaults.headers.post["X-CSRF-Token"] = cookies["rujukan-csrf"];
                  instance.post(uAPI + '/api/v1/log', formData, config)
                    .then(response => {
                      console.log(response)
                      if (response.data.status === 406) {
                        console.log(response)
                      } else {
                        console.log(response)
                      }
                    }).catch(function (error) { console.log(error) });
                }
              }, 1000);

            }
          })
          .catch((error) => {
            loginError("Terjadi Kesalahan!");
          });
      }
    } else {
      await loginError("Captha Salah!");
      document.getElementById("captha").value = "";
    }
  }

  async function loginPID(e) {
    e.preventDefault();
    await setLoader(true);
    let user_captcha = document.getElementById("captha").value;
    if (validateCaptcha(user_captcha) == true) {
      loadCaptchaEnginge(4, "#CCD9DD", "#000", "numbers");
      document.getElementById("captha").value = "";
      if (pid_user === "" && password === "") {
        await loginError("PID dan password harus diisi!");
      } else {
        await instance
          .get(uAPI + "/app/login-petugas?pid_user=" + pid_user + "&password=" + password)
          .then((response) => {
            console.log("ini response", response)
            let status = response.data.status;
            if (status !== 200) {
              //   loginError(JsonData.results);
              loginError("Login Gagal! PID atau Password Salah!");
            } else {
              if (rememberMe) {
                localStorage.rememberMe = rememberMe;
                localStorage.setItem("detail_login", JSON.stringify(detail_login));
              }
              let randomKey = Array(6).fill(0).map(x => Math.random().toString(36).charAt(2)).join('');
              setDetail_login({
                d_u: CryptoJS.AES.encrypt(
                  response.data.id_user.toString(),
                  randomKey
                ).toString(),
                kode_perusahaan: response.data.kode_perusahaan,
                nama_perusahaan: response.data.nama_perusahaan,
                idp: response.data.id_perusahaan,
                nama_user: response.data.nama_user,
                p_: randomKey,
                level: response.data.level.toString(),
                branch: response.data.branch_name
              });

              if (response.data.level == 1) {
                setAdmin(true);
              }

              // localStorage.status_login = true;
              loginSuccess("Login berhasil!");

              setTimeout(() => {
                if (cookies["rujukan-csrf"]) {
                  const formData = new FormData();
                  const config = { headers: { 'content-type': 'multipart/form-data' } };
                  formData.append('id_user', response.data.id_user);
                  formData.append('name', response.data.nama_user);
                  formData.append('message', "Login");
                  instance.defaults.headers.post["X-CSRF-Token"] = cookies["rujukan-csrf"];
                  instance.post(uAPI + '/api/v1/log', formData, config)
                    .then(response => {
                      console.log(response)
                      if (response.data.status === 406) {
                        console.log(response)
                      } else {
                        console.log(response)
                      }
                    }).catch(function (error) { console.log(error) });
                }
              }, 1000);



            }
          })
          .catch((error) => {
            loginError("Terjadi Kesalahan!");
          });
      }
    } else {
      await loginError("Captha Salah!");
      document.getElementById("captha").value = "";
    }
  }


  // console.log("kkk", login);
  // console.log("zzz", detail_login);
  // console.log("yyy", status_login);

  //rem
  if (login === true && detail_login) {
    return (
      <Redirect
        to={{
          pathname: admin ? "/pasien" : "/registration",
          state: {
            detail_login: detail_login,
          },
        }}
      />
    );
  } else if (cookies["rujukan-login"] != null) {
    return (
      <Redirect
        to={{
          pathname: admin ? "/pasien" : "/registration",
          state: {
            detail_login: JSON.parse(localStorage.getItem("_log")),
          },
        }}
      />
    );
  } else {
  }

  return (
    <>
      <nav
        style={{
          backgroundColor: "rgba(0,0,0,0.0)",
          padding: 20,
          position: "absolute",
          zIndex: 2,
          right: 0,
        }}
      >
        <img
          src={logo}
          width="200"
          alt="Logo"
          style={{ float: "right", padding: 20 }}
        />
      </nav>

      <div id="overlay" style={{ display: overlay }}>
        <div className="w-100 d-flex justify-content-center align-items-center">
          <div className="sk-chase">
            <div className="sk-chase-dot"></div>
            <div className="sk-chase-dot"></div>
            <div className="sk-chase-dot"></div>
            <div className="sk-chase-dot"></div>
            <div className="sk-chase-dot"></div>
            <div className="sk-chase-dot"></div>
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        dialogClassName="custom-modal"
        keyboard
        centered
      >
        <Modal.Header closeButton style={{ backgroundColor: "#1c4d6a" }}>
          <Modal.Title
            style={{ fontFamily: "var(--font-family-gotha-boo)", fontSize: 18 }}
          >
            Reset Password
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container-fluid">
            <div>
              <div className="card" style={{ border: "none" }}>
                <div className="card-body">
                  <div className="App">
                    <div className="form-group mb-3">
                      <div className="text-center d-flex justify-content-between mt-4">
                        <p className="input_label">Masukkan Email Terdaftar</p>
                      </div>
                      <input
                        className="form-control "
                        style={{
                          background: "#CCD9DD",
                          fontFamily: "var(--font-family-gotha-boo)",
                          fontSize: 14,
                          fontWeight: "bold",
                          boxShadow: "0px 4px 7px #8989",
                          border: 0,
                          transition: "0.6s ease",
                        }}
                        placeholder=""
                        type="text"
                        name="new_password"
                        id="myInput"
                        onChange={(e) => {
                          settomail(e.target.value);
                        }}
                        autoComplete="off"
                      />
                    </div>

                    <div className="submit-button">
                      {state.loader ? (
                        <Loader type="Circles" color="#00BFFF" height={40} width={100} />
                      ) : (
                        <div className="accordion-reset">
                          <input
                            type="submit"
                            onClick={sendmail}
                            name=""
                            style={{ display: displayReset }}
                            value="Kirim"
                          />
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="row">
                    <div style={{ margin: "auto" }}>
                      <div className="col-12"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer style={{ borderTop: "0px solid #ffffff" }}>
          <div className="back-button-mini">
            <input type="button" name="" onClick={handleClose} value="CLOSE" />
          </div>
        </Modal.Footer>
      </Modal>

      <div className="container-fluid">
        <div className="row no-gutter">
          <div className="col-md-7 rectangle-303">
            <div className="image-container">
              <img
                //  className="d-none d-sm-none d-md-none d-lg-block"
                src={bg316}
                alt="logo"
                width="70%"
              />
            </div>
          </div>
          <div className="col-md-5">
            <div className="login d-flex align-items-center py-4">
              <div className="container">
                <div className="row">
                  <div
                    className="col"
                    style={{
                      marginTop: 60,
                      marginLeft: 50,
                      marginRight: 50,
                    }}
                  >
                    <p className="title">User Login</p>
                    <p className="mb-4 welcome">Welcome</p>

                    <form onSubmit={petugas ? loginPID : login_}>

                      {petugas ? (
                        <div className="form-group mb-3">
                          <div className="text-center d-flex justify-content-between">
                            <p className="input_label">PID</p>
                          </div>
                          <input
                            id="inputPID"
                            name="pid_user"
                            type="text"
                            placeholder="PID"
                            //   required
                            //   autoFocus
                            autoComplete="off"
                            className="form-control rounded-pill border-0 px-4"
                            style={{
                              background: "#CCD9DD",
                              fontFamily: "var(--font-family-gotha-boo)",
                              fontSize: 14,
                              fontWeight: "bold",
                              boxShadow: "0px 4px 7px #8989",
                              transition: "0.6s ease",
                            }}
                            onChange={onChange}
                          />
                        </div>
                      ) : (
                        <div className="form-group mb-3" >
                          <div className="text-center d-flex justify-content-between">
                            <p className="input_label">Email</p>
                          </div>
                          <input
                            id="inputEmail"
                            name="email_user"
                            type="email"
                            placeholder="Email address"
                            //   required
                            //   autoFocus
                            autoComplete="off"
                            className="form-control rounded-pill border-0 px-4"
                            style={{
                              background: "#CCD9DD",
                              fontFamily: "var(--font-family-gotha-boo)",
                              fontSize: 14,
                              fontWeight: "bold",
                              boxShadow: "0px 4px 7px #8989",
                              transition: "0.6s ease",
                            }}
                            onChange={onChange}
                          />
                        </div>
                      )}






                      <div className="row">
                        <div className="col-6 mx-auto">
                          <div className="custom-checkbox">
                            <input
                              type="checkbox"
                              checked={state.petugas}
                              name="remember"
                              onChange={onChangePetugas}
                              style={{
                                marginLeft: "20px",
                                left: "-2px",
                                top: "1px",
                                width: "10px",
                                position: "absolute",
                              }}
                            />{" "}
                            <p
                              style={{
                                display: "inline-block",
                                fontSize: "12px",
                                color: "#1C4D6A",
                                fontWeight: "bolder",
                                marginLeft: "20px",
                                fontFamily: "var(--font-family-gotha-bol)",
                              }}
                            >
                              Petugas Biomedika
                            </p>
                          </div>
                        </div>
                        <div className="col-6 mx-auto">
                          {/* <a
                            href="#"
                            style={{
                              fontSize: "12px",
                              float: "right",
                              color: "#1C4D6A",
                              fontWeight: "bold",
                              fontFamily: "var(--font-family-gotha-bol)",
                              marginTop: "3px",
                            }}
                            onClick={handleShow}
                          >
                            Forgot Password?
                          </a> */}
                        </div>
                      </div>

                      <div className="form-group mb-3">
                        <div className="text-center d-flex justify-content-between mt-4">
                          <p className="input_label">Password</p>
                        </div>
                        <input
                          className="form-control rounded-pill border-0 px-4"
                          style={{
                            background: "#CCD9DD",
                            fontFamily: "var(--font-family-gotha-boo)",
                            fontSize: 14,
                            fontWeight: "bold",
                            boxShadow: "0px 4px 7px #8989",
                            transition: "0.6s ease",
                          }}
                          required
                          placeholder="************"
                          type={hidden ? "password" : "text"}
                          name="password"
                          id="myInput"
                          onChange={onChange}
                          autoComplete="off"
                        />

                        <div>
                          <a href="#" className="psw_child" onClick={toggleShow}>
                            {hidden === true ? (
                              <i className="fa fa-eye"></i>
                            ) : (
                              <i className="fa fa-eye-slash"></i>
                            )}
                          </a>
                        </div>

                        <br />
                        <input
                          id="captha"
                          name="captha"
                          type="text"
                          placeholder="Enter Captha"
                          required
                          autoFocus
                          autoComplete="off"
                          className="form-control rounded-pill border-0 px-4"
                          style={{
                            background: "#CCD9DD",
                            fontFamily: "var(--font-family-gotha-boo)",
                            fontSize: 14,
                            fontWeight: "bold",
                            boxShadow: "0px 4px 7px #8989",
                            transition: "0.6s ease",
                          }}
                        // onChange={onChange}
                        />
                        <br />
                        <LoadCanvasTemplate
                          reloadText="Reload Captcha"
                          reloadColor="blue"
                        />
                      </div>
                      <div className="row">
                        <div className="col-6 mx-auto">
                          <div className="custom-checkbox mb-3">
                            <input
                              type="checkbox"
                              checked={state.rememberMe}
                              name="remember"
                              onChange={onChangeRemember}
                              style={{
                                marginLeft: "20px",
                                left: "-2px",
                                top: "1px",
                                width: "10px",
                                position: "absolute",
                              }}
                            />{" "}
                            <p
                              style={{
                                display: "inline-block",
                                fontSize: "12px",
                                color: "#1C4D6A",
                                fontWeight: "bolder",
                                marginLeft: "20px",
                                fontFamily: "var(--font-family-gotha-bol)",
                              }}
                            >
                              Remember Me
                            </p>
                          </div>
                        </div>
                        <div className="col-6 mx-auto">
                          {/* <a
                            href="#"
                            style={{
                              fontSize: "12px",
                              float: "right",
                              color: "#1C4D6A",
                              fontWeight: "bold",
                              fontFamily: "var(--font-family-gotha-bol)",
                              marginTop: "3px",
                            }}
                            onClick={handleShow}
                          >
                            Forgot Password?
                          </a> */}
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="submit-button text-center">
                          {state.loader ? (
                            <Loader type="Circles" color="#00BFFF" height={40} width={100} />
                          ) : (
                            <div className="login-button">
                              <input type="submit" name="" value="LOGIN" />
                            </div>
                          )}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="container">
              <span className="icon"></span>
              <div className="social">
                <p
                  style={{
                    fontFamily: "var(--font-family-gotha-boo)",
                    fontSize: "12px",
                  }}
                >
                  {" "}
                  CONTACT US ON:
                </p>
                <a href="#">
                  <i className="social-instagram"></i>
                </a>
                <a href="#">
                  <i className="social-web"></i>
                </a>
                <a href="#">
                  <i className="social-email"></i>
                </a>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <ToastContainer
        position="bottom-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}
