import React from "react";
import ReactDOM from "react-dom";
import Router from "./config/Router";
import "./index.scss";
// import "./assets/scss/style.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import reportWebVitals from "./reportWebVitals";
import "./assets/page1.css";
import "./assets/style.css";
import "./globals.css";
import "./styleguide.css";


import { CookiesProvider } from "react-cookie";

ReactDOM.render(
 <CookiesProvider>
  <Router />
 </CookiesProvider>,
 document.getElementById("root")
);

reportWebVitals();
