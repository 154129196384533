import React, { Component, useState, useEffect } from "react";
import { Helmet } from "react-helmet";
// import "./assets/page1.css";
// import "./assets/style.css";
import "./assets/header.css";
import logo from "./images/bio_logo.png";
import { useHistory, Redirect, Link } from "react-router-dom";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import Button from "react-bootstrap/Button";
import axios from "axios";
import { uAPI, IP } from "./config/Config";
import { useCookies } from "react-cookie";
import Cookies from 'js-cookie';
import Modal from "react-bootstrap/Modal";
import moment from "moment";
import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";

export default function Header() {
    const history = useHistory();
    const [state, setState] = React.useState();
    const [lastLocation, setLastLocation] = React.useState();
    const [loginName, setLoginName] = React.useState();
    const [loginPerusahaan, setLoginPerusahaan] = React.useState();
    const [loginBranch, setLoginBranch] = React.useState();
    const [branchName, setBranchName] = React.useState();
    const [cookies, setCookie, removeCookie] = useCookies();
    const [lvl, setlvl] = React.useState();
    const [pasien, setPasien] = useState([]);
    const [show, setShow] = useState(false);
    const handleClose = async () => {
        setShow(false);
        detailPatient(1);
    };
    const handleShow = () => setShow(true);
    const [items, setItems] = useState([]);

    const instance = axios.create({
        withCredentials: true,
    });

    useEffect(() => {
        //   console.log(history.location.state);

        if (history.location.state === null && cookies["rujukan-login"] == undefined) {
            // setRedirctTo(true);
        } else if (history.location.state != null && cookies["rujukan-login"] == undefined) {
            // setRedirctTo(true);
        } else if (history.location.state == undefined && cookies["rujukan-login"] == undefined) {
            // setRedirctTo(true);
        } else if (
            (history.location.state === null || history.location.state === undefined) &&
            cookies["rujukan-login"] != undefined
        ) {
            const items = JSON.parse(localStorage.getItem("_log"));
            if (items) {
                setItems(items);
                setLastLocation(history.location.pathname);
                setLoginName(items.nama_user);
                setLoginPerusahaan(items.nama_perusahaan);
                setLoginBranch(items.branch);
            }
        } else {
            setState(history.location.state);
            setLastLocation(history.location.pathname);
            setLoginName(history.location.state["detail_login"].nama_user);
            setLoginPerusahaan(history.location.state["detail_login"].nama_perusahaan);
            setLoginBranch(history.location.state["detail_login"].branch);
        }

        setTimeout(() => {
            if (localStorage._log) {
                setlvl(JSON.parse(localStorage._log).level);
            }
        }, 1000);
    }, []);


    const numberFormat = (value) =>
        new Intl.NumberFormat("id-ID", {
            style: "currency",
            currency: "IDR",
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        }).format(value);


    const goToPage1 = () => {
        history.push({
            pathname: "/pasien",
            state: state,
        });
    };

    const goToReg = () => {
        history.push({
            pathname: "/registration",
            state: state,
        });
    };

    const logout = () => {

        instance.defaults.headers.post["X-CSRF-Token"] = cookies["rujukan-csrf"];
        instance({
            method: "post",
            url: uAPI + "/logout",
        })
            .then((response) => {
                console.log(response);

                Cookies.remove("rujukan-login", { path: '/', domain: '.biomedika.co.id' })
                Cookies.remove("rujukan-csrf", { path: '/', domain: '.biomedika.co.id' })
                Cookies.remove("rujukan-session", { path: '/', domain: '.biomedika.co.id' })
                Cookies.remove("rujukan-userID", { path: '/', domain: '.biomedika.co.id' })
                localStorage.clear();
                sessionStorage.clear();

                // setTimeout(() => {
                    history.push({
                        pathname: "/",
                        status: "logout",
                        login_alert: {
                            msg: "Berhasil Log Out",
                        },
                    });
                // }, 2000);



            })
            .catch((error) => {
                console.log(error);
            });


    };

    function onChange(e) {
        detailPatient(e.target.value);
    }


    async function detailPatient(id) {

        let url = "";
        url = `/api/v1/getDetailPatient/` + id;
        instance.defaults.headers.post["X-CSRF-Token"] = cookies["rujukan-csrf"];
        await instance
            .post(uAPI + url)
            .then((response) => {
                console.log("ini response", response.data["results"])
                let pasien = [];
                response.data["results"].map(function (key) {
                    pasien.push({
                        id: key.id_pasien,
                        nama_pasien: key.nama_pasien,
                        tgl_lahir: moment(key.tgl_lahir).format("DD-MM-YYYY"),
                        no_telp: key.no_telp,
                        patient_id: key.patient_id,
                        alamat: key.alamat,
                        email: key.email,
                        checkout: key.checkout,
                        tgl_periksa: moment(key.tgl_periksa).format("YYYY-MM-DD"),
                        total_harga: numberFormat(key.total_harga),
                        test: key.test,
                        nama_user: key.nama_user,
                        nama_perusahaan: key.nama_perusahaan,
                        email: key.email,
                        alamat: key.alamat,
                        no_telp: key.no_telp,
                        status_pembayaran: key.status_pembayaran,
                        catatan: key.catatan,
                        status: key.status,
                        diagnosis_klinis: key.diagnosis_klinis,
                        petugas: key.petugas,
                        branch_name: key.branch_name,
                        created_date: key.created_date,
                        type: key.type
                    });
                });
                console.log("ini pasien", pasien);
                setPasien(pasien);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: "btn btn-success",
            cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
    });

    async function onClickSelesai(e, v) {
        // console.log("ini edisi", e.target + "---" + v)
        // alert(v);

        await swalWithBootstrapButtons
            .fire({
                text: "Apakah Anda yakin mengubah status registrasi pasien?",
                toast: true,
                showCancelButton: true,
                confirmButtonText: "Save",
            })
            .then((result) => {
                if (result.isConfirmed) {
                    updateStatus(v);
                }
            });


    }

    async function updateStatus(id) {
        let url = `/api/v1/updateStatus/` + id + `?ptgs=${loginName}` + `&b=${loginBranch}`
        instance.defaults.headers.post["X-CSRF-Token"] = cookies["rujukan-csrf"];;
        await instance
            .post(uAPI + url)
            .then((response) => {
                console.log("ini response", response.data["results"]);
                alert("Status berhasil diubah");
                window.location.reload();
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const getKeyByValue = (object, value) => {
        var x = Object.keys(object);
        var y = [];
        x.map((el, i) => {
            if (object[el].find(z => z.id == value) != undefined) {
                y.push(object[el].find(z => z.id == value).new_desc)
            }
        })
        return y[0];
    };


    return (
        <React.Fragment>
            <Helmet>
                <meta http-equiv="content-type" content="text/html; charset=UTF-8" />
                <title>Bio Medika - Rujukan</title>
                <meta http-equiv="content-type" content="text/html; charset=UTF-8" />
                <meta name="robots" content="noindex, nofollow" />
                <meta name="googlebot" content="noindex, nofollow" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <body />
            </Helmet>
            <div>
                <nav
                    className="navbar navbar-icon-top navbar-expand-lg transparent navbar-inverse navbar-fixed-top"
                    style={{
                        backgroundColor: "rgba(0,0,0,0.0)",
                        fontFamily: "var(--font-family-raleway-bold)",
                    }}
                >
                    <a className="navbar-brand" href="#"></a>
                    <button
                        className="navbar-toggler"
                        type="button"
                        data-toggle="collapse"
                        data-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav mr-auto">
                            <img src={logo} width="200" alt="Logo" style={{ padding: 20 }} />
                        </ul>

                        {lvl != 1 ? (
                            <ul className="navbar-nav ">
                                <li className="nav-item">
                                    <div className="nav-link next-button-mini">
                                        <input type="button" name="" onClick={handleShow} value="Scan QR" />
                                    </div>
                                </li>
                                {lastLocation == "/pasien" ? (
                                    <li className="nav-item">
                                        <div className="nav-link next-button-mini">
                                            <input type="button" name="" onClick={goToReg} value="Registrasi" />
                                        </div>
                                    </li>
                                ) : (
                                    <li className="nav-item">
                                        <div className="nav-link next-button-mini">
                                            <input type="button" name="" onClick={goToPage1} value="Cek Data" />
                                        </div>
                                    </li>
                                )}
                                <li className="nav-item">
                                    <OverlayTrigger
                                        trigger="click"
                                        placement="bottom"
                                        rootClose={true}
                                        overlay={
                                            <Popover
                                            //   style={{backgroundColor:'#CCD9DD'}}
                                            >
                                                <div style={{ margin: "10px" }}>
                                                    <p
                                                        style={{
                                                            fontWeight: "bold",
                                                            fontFamily: "var(--font-family-gotha-boo)",
                                                            fontSize: "12px",
                                                            // marginBottom: "10px",
                                                        }}
                                                    >
                                                        {loginName}{" "}
                                                    </p>
                                                    <p
                                                        style={{
                                                            // fontWeight: "bold",
                                                            fontFamily: "var(--font-family-gotha-boo)",
                                                            fontSize: "10px",
                                                            marginBottom: "10px",
                                                        }}
                                                    >
                                                        {loginPerusahaan}{" "}
                                                    </p>
                                                    <div className="list-group text-center">
                                                        <div className="next-button-mini">
                                                            <input type="button" name="" onClick={logout} value="Log Out" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </Popover>
                                        }
                                    >
                                        <div className="nav-link back-button-mini">
                                            <input type="button" name="" value="Profile" />
                                        </div>
                                    </OverlayTrigger>
                                </li>
                                {/* <li className="nav-item">
            <a className="nav-link" href="#"></a>
            </li> */}
                            </ul>
                        )
                            : null

                        }


                        {lvl == 1 ? (
                            <ul className="navbar-nav ">
                                <li className="nav-item">
                                    <div className="nav-link next-button-mini">
                                        <input type="button" name="" onClick={handleShow} value="Scan QR" />
                                    </div>
                                </li>
                                {lastLocation == "/pasien" ? (
                                    <li className="nav-item">
                                        <div className="nav-link next-button-mini">
                                            <input type="button" name="" onClick={goToReg} value="Registrasi" />
                                        </div>
                                    </li>
                                ) : (
                                    <li className="nav-item">
                                        <div className="nav-link next-button-mini">
                                            <input type="button" name="" onClick={goToPage1} value="Cek Data" />
                                        </div>
                                    </li>
                                )}
                                <li className="nav-item">
                                    <OverlayTrigger
                                        trigger="click"
                                        placement="bottom"
                                        rootClose={true}
                                        overlay={
                                            <Popover
                                            //   style={{backgroundColor:'#CCD9DD'}}
                                            >
                                                <div style={{ margin: "10px" }}>
                                                    <p
                                                        style={{
                                                            fontWeight: "bold",
                                                            fontFamily: "var(--font-family-gotha-boo)",
                                                            fontSize: "12px",
                                                            // marginBottom: "10px",
                                                        }}
                                                    >
                                                        {loginName}{" "}
                                                    </p>
                                                    <p
                                                        style={{
                                                            // fontWeight: "bold",
                                                            fontFamily: "var(--font-family-gotha-boo)",
                                                            fontSize: "10px",
                                                            marginBottom: "10px",
                                                        }}
                                                    >
                                                        Bio Medika {loginBranch}{" "}
                                                    </p>
                                                    <div className="list-group text-center">
                                                        <div className="next-button-mini">
                                                            <input type="button" name="" onClick={logout} value="Log Out" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </Popover>
                                        }
                                    >
                                        <div className="nav-link back-button-mini">
                                            <input type="button" name="" value="Profile" />
                                        </div>
                                    </OverlayTrigger>
                                </li>
                            </ul>
                        )
                            : null

                        }

                    </div>
                </nav>

                <Modal
                    show={show}
                    onHide={handleClose}
                    backdrop="static"
                    dialogClassName="custom-modal"
                    keyboard
                    centered
                >

                    <Modal.Body>
                        <div className="container-fluid">
                            <div id="ui-view" data-select2-id="ui-view">
                                <div>
                                    <div className="card">
                                        <div className="card-body">


                                            <form
                                                style={{
                                                    padding: "0px 100px 0px 20px",
                                                    fontFamily: "var(--font-family-gotha-boo)",
                                                }}
                                            >

                                                <div className="row mb-3">
                                                    <label
                                                        htmlFor="pid"
                                                        className="col-sm-4 col-form-label input_label"
                                                        style={{
                                                            fontSize: 14,
                                                            color: "#000",
                                                        }}
                                                    >
                                                        No. Registrasi
                                                    </label>
                                                    <div className="col-sm-8">
                                                        <input
                                                            // disabled
                                                            // readOnly
                                                            autofocus
                                                            type="text"
                                                            className="form-control rounded-pill border-0 px-4"
                                                            id="pid"
                                                            // value={pid}
                                                            onChange={onChange}
                                                            placeholder="PID  "
                                                            autoComplete="off"
                                                            style={{
                                                                marginLeft: 20,
                                                                background: "rgba(222,230,232,1)",
                                                                fontFamily: "var(--font-family-gotha-boo)",
                                                                fontSize: 14,
                                                                color: "#000",
                                                                boxShadow: "0px 3px 8px 0.2px rgba(77,77,77,0.2)",
                                                                transition: "0.6s ease",
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </form>

                                            <hr
                                                style={{
                                                    border: "0.5px solid #888",
                                                    marginTop: "1px",
                                                    marginBottom: "2px",
                                                }}
                                            />

                                            {pasien[0] ? (
                                                <div style={{ marginTop: "4px", }}>
                                                    <div style={{ backgroundColor: '#a0d8d8', padding: 10 }}>
                                                        <table style={{ border: 'none', backgroundColor: '#a0d8d8', marginBottom: 10 }}>

                                                            <tr>
                                                                <th>NAMA:</th>
                                                                <th><p style={{ fontWeight: 'bold' }}>{pasien[0].nama_pasien}</p></th>
                                                            </tr>
                                                            <tr >
                                                                <th>TGL LAHIR:</th>
                                                                <th><p style={{ fontWeight: 'bold' }}>{pasien[0].tgl_lahir}</p></th>
                                                            </tr>
                                                            <tr>
                                                                <th>TGL RUJUKAN:</th>
                                                                <th><p style={{ fontWeight: 'bold' }}>{moment(pasien[0].created_date).format("DD MMMM YYYY - hh:mm")}</p></th>
                                                            </tr>


                                                            <tr>
                                                                <th>ALAMAT:</th>
                                                                <th><p style={{ fontWeight: 'bold' }}>{pasien[0].alamat}</p></th>
                                                            </tr>


                                                            <tr>
                                                                <th>NO. TELP:</th>
                                                                <th><p style={{ fontWeight: 'bold' }}>{pasien[0].no_telp}</p></th>
                                                            </tr>



                                                            <tr>
                                                                <th>EMAIL:</th>
                                                                <th><p style={{ fontWeight: 'bold' }}>{pasien[0].email}</p></th>
                                                            </tr>

                                                            <tr>
                                                                <th>PERUJUK:</th>
                                                                <th><p style={{ fontWeight: 'bold' }}>{pasien[0].nama_user}</p></th>
                                                            </tr>
                                                            <tr>
                                                                <th>PERUJUK:</th>
                                                                <th><p style={{ fontWeight: 'bold' }}>{pasien[0].nama_perusahaan}</p></th>
                                                            </tr>
                                                            <tr>
                                                                <th>PEMBAYARAN:</th>
                                                                <th><p style={{ fontWeight: 'bold' }}>{pasien[0].status_pembayaran}</p></th>
                                                            </tr>
                                                            <tr style={{ display: pasien[0].catatan == null ? 'none' : null }}>
                                                                <th>CATATAN:</th>
                                                                <th><p style={{ fontWeight: 'bold' }}>{pasien[0].catatan}</p></th>
                                                            </tr>
                                                            <tr style={{ display: pasien[0].diagnosis_klinis == null ? 'none' : null }}>
                                                                <th>DIAGNOSIS KLINIS</th>
                                                                <th><p style={{ fontWeight: 'bold' }}>{pasien[0].diagnosis_klinis}</p></th>
                                                            </tr>
                                                            <tr style={{ display: pasien[0].petugas == null ? 'none' : null }}>
                                                                <th>PETUGAS</th>
                                                                <th><p style={{ fontWeight: 'bold' }}>{pasien[0].petugas} - {pasien[0].branch_name}</p></th>
                                                            </tr>



                                                            <tr>
                                                                <th>REGISTRASI:</th>
                                                                {pasien[0].type == 1 ? (<th><p style={{ fontWeight: 'bold' }}>SAMPLE</p></th>) : null}
                                                                {pasien[0].status == "SELESAI" ? (<th><p style={{ fontWeight: 'bold' }}>SELESAI</p></th>) : null}
                                                                {pasien[0].type != 1 && pasien[0].status != "SELESAI" ? (<th><p style={{ fontWeight: 'bold' }}>BELUM SELESAI</p></th>) : null}
                                                            </tr>
                                                        </table>
                                                    </div>

                                                    <div style={{ backgroundColor: '#D6EEEE', padding: 10 }}>
                                                        <table style={{ border: 'none', backgroundColor: '#D6EEEE', marginBottom: 10, textAlign: 'left' }}>
                                                            <tr>
                                                                <th style={{}}><p style={{ fontWeight: "bold" }}>DETAIL PEMERIKSAAN</p></th>
                                                            </tr>
                                                            <tr>
                                                                <th>
                                                                    <ul
                                                                        style={{
                                                                            listStyleType: "number",
                                                                            lineHeight: "24px",
                                                                            padding: "0px 20px 0px 20px",
                                                                        }}
                                                                    >
                                                                        {JSON.parse(pasien[0].checkout).map(function (x) {
                                                                            return <>

                                                                                <li> {x.desc + " - " + numberFormat(x.price)} </li>
                                                                                {x.new_desc == "" ? <p> {getKeyByValue(JSON.parse(pasien[0].test), x.id)} </p> : <p>{x.new_desc} </p>}
                                                                            </>;
                                                                        })}
                                                                    </ul>
                                                                </th>
                                                            </tr>
                                                        </table>
                                                    </div>

                                                    <div style={{ backgroundColor: '#a0d8d8', padding: 10 }}>
                                                        <table style={{ border: 'none', backgroundColor: '#a0d8d8' }}>
                                                            <tr>
                                                                <th>Total:</th>
                                                                <th><p style={{ fontWeight: 'bold' }}>{pasien[0].total_harga}</p></th>
                                                            </tr>
                                                        </table>

                                                    </div>

                                                    <div style={{ float: 'left', marginTop: 10, marginBottom: 10 }}>
                                                        <button
                                                            className="btn btn-sm btn-outline-primary py-0"
                                                            type="button"
                                                            style={{ fontSize: "1em", marginRight: 4, display: pasien[0].status == "SELESAI" || JSON.parse(localStorage._log).level == 2 ? 'none' : null }}
                                                            onClick={e => onClickSelesai(e, pasien[0].id)}
                                                        >
                                                            Selesai {pasien[0].status}
                                                        </button>
                                                    </div>

                                                </div>

                                            ) : (<p>data tidak ditemukan...</p>)}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer style={{ borderTop: "0px solid #ffffff" }}>
                        {/* <div className="next-button">
                            <input type="button" name="" value="PRINT" />
                        </div> */}
                        <div className="back-button">
                            <input type="button" name="" onClick={handleClose} value="CLOSE" />
                        </div>
                    </Modal.Footer>
                </Modal>

            </div>
        </React.Fragment>
    );
}
