import React, { Component } from "react";
import Login from "../Login";
import Book from "../Book";
import Page1 from "../Page1";
import ResetPass from "../ResetPassword.js";
import "../assets/style.css";
import { BrowserRouter, Route, Switch } from "react-router-dom";

class Router extends Component {
 render() {
  return (
   <BrowserRouter>
    <Switch>
     <Route path="/" exact component={Login} />
    </Switch>

    <Switch>
     <Route path="/registration" exact component={Book} />
     <Route path="/pasien" exact component={Page1} />
    </Switch>
   </BrowserRouter>
  );
 }
}

export default Router;
